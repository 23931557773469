import { graphql, useStaticQuery } from 'gatsby'
import pluralize from 'pluralize'

const queryAirtableCategories = () => {
  const query = useStaticQuery(
    graphql`
      query CategoriesQuery {
        query: allAirtable(filter: { table: { eq: "Categories" } }) {
          edges {
            node {
              id
              data {
                Category_Name
                Category_Introduction
                Category_Details
                Category_Slug
                Category_Image {
                  url
                  thumbnails { large { url } }
                }
                Category_Types {
                  id
                  data {
                    Type_Name
                    Type_Slug
                    Type_Image {
                      url
                      thumbnails { large { url } }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const {
    query: { edges: categoriesData },
  } = query

  const categories = categoriesData.map(category => ({
    id: category.node.id,
    name: pluralize(category.node.data.Category_Name),
    introduction: category.node.data.Category_Introduction,
    details: category.node.data.Category_Details,
    slug: category.node.data.Category_Slug,
    types: category.node.data.Category_Types.map(type => ({
      id: type.id,
      name: pluralize(type.data.Type_Name),
      slug: type.data.Type_Slug,
      image: type.data.Type_Image ? {
        full: type.data.Type_Image[0].url,
        thumbnail: type.data.Type_Image[0].thumbnails.large.url
      } : null
    })),
    image: category.node.data.Category_Image ? {
      full: category.node.data.Category_Image[0].url,
      thumbnail: category.node.data.Category_Image[0].thumbnails.large.url
    } : null
  }))

  return categories
}

export default queryAirtableCategories
